<template>
  <div>
    <v-dialog v-model='isPrint' max-width='600' persistent>
      <v-card>
        <v-card-title class='d-md-flex justify-content-between'>
          {{
            statusPath == 'invoice' || statusPath == 'invoice2'
              ? $t('print_invoice')
              : statusPath == 'deposit'
                ? $t('print_deposit_receipt')
                : $t('print_receipt_vat')
          }}
          <v-spacer></v-spacer>
          <v-radio-group v-model='language' class='mt-0 pt-0' row hide-details>
            <v-radio label='ไทย' value='1'></v-radio>
            <v-radio label='English' value='2'></v-radio>
          </v-radio-group>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols='12' md='5'>
              <v-radio-group v-model='paperSize' class='pt-0 mt-0'>
                <template v-slot:label>
                  <span class='font-weight-semibold text-md'>
                    {{ $t('choose_the_paper_size') }}
                  </span>
                </template>
                <v-radio label='A4' value='1'></v-radio>
                <v-radio label='A5' value='2'></v-radio>
                <v-radio v-if="printType == '1'" label='80 mm.' value='3'></v-radio>
                <v-divider></v-divider>
                <v-radio-group v-model='topicSelect' class='pt-0'>
                  <template v-slot:label>
                    <span class='font-weight-semibold text-md'>
                      {{ $t('topicReceipt') }}
                    </span>
                  </template>
                  <v-radio :label="$t('receipt_tax_invoice')" value='1'></v-radio>
                  <v-radio :label="$t('a_receipt')" value='2'></v-radio>
                  <v-radio :label="$t('tax_invoice')" value='3'></v-radio>
                </v-radio-group>
              </v-radio-group>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols='12' md='7'>
              <span class='font-weight-semibold text-md'>
                {{ $t('option') }}
              </span>
              <v-checkbox
                v-model='showByCompany'
                class='mt-0'
                :label="`${$t('print_company')}`"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model='shop_print_type'
                :label="`${$t('useClinicData')}`"
                true-value='2'
                false-value='1'
                hide-details
              ></v-checkbox>
              <v-checkbox v-model='showDrugStatus' :label="$t('not_show_drug_list')"
                          hide-details></v-checkbox>
              <v-checkbox v-model='showCourseStatus' :label="$t('not_show_checking_course')"
                          hide-details></v-checkbox>
              <v-checkbox v-model='showPage' :label="$t('showPageNumber')"
                          hide-details></v-checkbox>
              <v-checkbox v-model='showPoint' :label="$t('checkShowPoint')"
                          hide-details></v-checkbox>
              <v-checkbox v-model='showComment' :label="$t('notShowComment')"
                          hide-details></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' @click='sendPrint'>
            {{ $t('print') }}
          </v-btn>
          <v-btn color='secondary' outlined @click="$emit('update:isPrint', false)">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {},
  model: {
    prop: 'isPrint',
    event: 'update:isPrint',
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
    statusPath: {
      type: String,
      default: '',
    },
    orderPayId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const printType = ref('1')
    const paperSize = ref('1')
    const language = ref('1')
    const showPage = ref(false)
    const showByCompany = ref(false)
    const showDrugStatus = ref(false)
    const showCourseStatus = ref(false)
    const options = ref([])
    const receiptId = ref('')
    const topicSelect = ref('1')
    const showPoint = ref(false)
    const showComment = ref(false)
    const shop_print_type = ref('1')

    const sendPrint = () => {
      const data = {
        id: props.orderId,
        showCompany: showByCompany.value,
        showDrugStatus: showDrugStatus.value,
        showCourseStatus: showCourseStatus.value,
        shop_print_type: shop_print_type.value,
        showPage: showPage.value,
        showComment: showComment.value,
        topic: topicSelect.value,
        point: showPoint.value,
        lang: language.value,
      }
      if (props.statusPath == 'receipt') {
        if (paperSize.value == '1') {
          const routeData = router.resolve({
            name: 'PrintOrder',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else if (paperSize.value == '2') {
          const routeData = router.resolve({
            name: 'PrintOrderA5',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else {
          const routeData = router.resolve({
            name: 'PrintOrder80',
            query: data,
          })
          window.open(routeData.href, '_blank')
        }
      } else if (props.statusPath == 'deposit') {
        if (paperSize.value == '1') {
          const routeData = router.resolve({
            name: 'PrintDeposit',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else if (paperSize.value == '2') {
          const routeData = router.resolve({
            name: 'PrintDepositA5',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else {
          const routeData = router.resolve({
            name: 'PrintDeposit80',
            query: data,
          })
          window.open(routeData.href, '_blank')
        }
      } else if (props.statusPath == 'invoice') {
        // ฉบับร่าง
        if (paperSize.value == '1') {
          const routeData = router.resolve({
            name: 'PrintInvoice',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else if (paperSize.value == '2') {
          const routeData = router.resolve({
            name: 'PrintInvoiceA5',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else {
          const routeData = router.resolve({
            name: 'PrintInvoice80',
            query: data,
          })
          window.open(routeData.href, '_blank')
        }
      } else if (props.statusPath == 'invoice2') {
        // ชำระค่างวด
        data.orderPayId = props.orderPayId
        if (paperSize.value == '1') {
          const routeData = router.resolve({
            name: 'PrintInvoice2',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else if (paperSize.value == '2') {
          const routeData = router.resolve({
            name: 'PrintInvoice2A5',
            query: data,
          })
          window.open(routeData.href, '_blank')
        } else {
          const routeData = router.resolve({
            name: 'PrintInvoice280',
            query: data,
          })
          window.open(routeData.href, '_blank')
        }
      }
    }

    watch(
      () => props.isPrint,
      value => {
        if (value) {
          printType.value = '1'
          paperSize.value = '1'
          language.value = '1'
          showByCompany.value = false
          showDrugStatus.value = false
          showCourseStatus.value = false
          shop_print_type.value = '1'
          showPoint.value = false
        }
      },
    )

    return {
      printType,
      paperSize,
      language,
      showByCompany,
      showDrugStatus,
      showPage,
      topicSelect,
      showCourseStatus,
      options,
      receiptId,
      shop_print_type,
      showComment,
      showPoint,
      sendPrint,
    }
  },
}
</script>
